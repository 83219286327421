import { ArrowPathIcon, CloudArrowUpIcon, FingerPrintIcon, LockClosedIcon } from '@heroicons/react/24/outline'

const people = [
    {
      name: 'Shubathara',
      role: 'Founder',
      imageUrl:
        'https://lh3.googleusercontent.com/a/ACg8ocJD3aNOb56tcj8Av75tY-UerO292Ao99KwWzhkdmsWySt8=s576-c-no'
    }    
]

const products = [{
        id: 1,
        name: 'Yellow Clock',
        href: '#',
        //imageSrc: 'https://drive.google.com/uc?id=1YBDsigwUyl4UFr9dpEesyufChFnV254o',
        imageSrc: 'https://ik.imagekit.io/l3gffggj0/shubify/gallery_images/Shubify-10.jpg?updatedAt=1709474988601',
        imageAlt: "Shubify Clock",
        price: '***',
        color: 'Yellow',
    },
    {
        id: 2,
        name: 'Medium Basic Tee',
        href: '#',
        //imageSrc: 'https://drive.google.com/uc?id=1KCnyAQ8RW40Y3uATzpI-P-XCkjA4lZAr',
        imageSrc: 'https://ik.imagekit.io/l3gffggj0/shubify/gallery_images/Shubify-1.jpg?updatedAt=1709474988613',
        imageAlt: "Front of men's Basic Tee in black.",
        price: '***',
        color: 'Black',
    },
    {
        id: 3,
        name: 'Semi Basic Tee',
        href: '#',
        //imageSrc: 'https://drive.google.com/uc?id=1d7TaICDzrHmmaxbKH63K09-3RmvEWzBG',
        imageSrc: 'https://ik.imagekit.io/l3gffggj0/shubify/other_images/shubify_rangoli_lighting.png?updatedAt=1709474996749',
        imageAlt: "Front of men's Basic Tee in black.",
        price: '***',
        color: 'Black',
    },
    {
        id: 4,
        name: 'Large Basic Tee',
        href: '#',
        //imageSrc: 'https://drive.google.com/uc?id=1uzHYJOKYjjKKIMd9vayqBvx9cQPGwbBw',
        imageSrc: 'https://ik.imagekit.io/l3gffggj0/shubify/other_images/shubify_chamundeswari_1.png?updatedAt=1709474996993',
        imageAlt: "Front of men's Large Tee in black.",
        price: '***',
        color: 'Black',
    },
    // More products...
]

const features = [
    {
      name: 'Acrylic Paintings',
      description:
        'Acrylic painting techniques are different styles of manipulating and working with polymer-based acrylic paints',
      icon: CloudArrowUpIcon,
    },
    {
      name: 'Customised Gifts',
      description:
        'Customized gifts are gifts that are specifically designed or created for a particular person or occasion. They are often more personal and meaningful than generic gifts, and can be a great way to show someone how much you care',
      icon: LockClosedIcon,
    },
    {
      name: 'Custom Wooden Frames',
      description:
        'A picture frame is a protective and decorative edging for a picture, such as a painting or photograph',
      icon: ArrowPathIcon,
    },
    {
      name: 'Painting Classes',
      description:
        'Painting is a visual art, which is characterized by the practice of applying paint, pigment, color or other medium to a solid surface',
      icon: LockClosedIcon,
    },
    {
        name: 'Mandala Painting',
        description:
          'A mandala generally represents the spiritual journey, starting from outside to the inner core, through layers.',
        icon: FingerPrintIcon,
    },
    {
        name: 'Madhubani Art',
        description:
          'Madhubani art (also Mithila art) is a style of painting practiced in the Mithila region of India and Nepal.',
        icon: LockClosedIcon,
    }
    
]

export default function App() {

    return ( 
        <div className = "bg-white" >

            <nav className="bg-white border-gray-200 dark:bg-gray-900">
                <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
                    <a href="#" className="flex items-center">
                        <img src="https://ik.imagekit.io/l3gffggj0/shubify/logo/Shubify-Logo-2.png?updatedAt=1709474994729" referrerPolicy="no-referrer" className="h-8 mr-3" alt="Shubify Logo"></img>
                        <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Shubify</span>
                    </a>
                    <div className="hidden w-full md:block md:w-auto" id="navbar-default">
                        <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                            <li>
                            <a href="#" className="block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white md:dark:text-blue-500" aria-current="page">Home</a>
                            </li>
                            <li>
                            <a href="#" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Contact</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

            <div className="relative overflow-hidden bg-white">
                <div className="pb-80 pt-16 sm:pb-40 sm:pt-24 lg:pb-48 lg:pt-40">
                    <div className="relative mx-auto max-w-7xl px-4 sm:static sm:px-6 lg:px-8">
                        <div className="sm:max-w-lg">
                            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                            Shubify arts &amp; crafts is finally here
                            </h1>
                            <p className="mt-4 text-xl text-gray-500">
                            Passionate about art and its ability to evoke emotions, spark conversations, and enhance everyday life
                            </p>
                        </div>
                        <div>
                            <div className="mt-10">
                            {/* Decorative image grid */}
                            <div
                                aria-hidden="true"
                                className="pointer-events-none lg:absolute lg:inset-y-0 lg:mx-auto lg:w-full lg:max-w-7xl"
                            >
                                <div className="absolute transform sm:left-1/2 sm:top-0 sm:translate-x-8 lg:left-1/2 lg:top-1/2 lg:-translate-y-1/2 lg:translate-x-8">
                                <div className="flex items-center space-x-6 lg:space-x-8">
                                    <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                                        <div className="h-64 w-44 overflow-hidden rounded-lg sm:opacity-0 lg:opacity-100">
                                            <img
                                            src="https://ik.imagekit.io/l3gffggj0/shubify/gallery_images/Shubify-9.png?updatedAt=1709474993816"
                                            referrerPolicy="no-referrer"
                                            alt=""
                                            className="h-full w-full object-cover object-center"
                                            ></img>
                                        </div>
                                        <div className="h-64 w-44 overflow-hidden rounded-lg">
                                            <img
                                            src="https://ik.imagekit.io/l3gffggj0/shubify/gallery_images/Shubify-8.png?updatedAt=1709474993765"
                                            referrerPolicy="no-referrer"
                                            alt=""
                                            className="h-full w-full object-cover object-center"
                                            ></img>
                                        </div>
                                    </div>
                                    <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                                        <div className="h-64 w-44 overflow-hidden rounded-lg">
                                            <img
                                            src="https://ik.imagekit.io/l3gffggj0/shubify/gallery_images/Shubify-13.png?updatedAt=1709474991979"
                                            referrerPolicy="no-referrer"
                                            alt=""
                                            className="h-full w-full object-cover object-center"
                                            ></img>
                                        </div>
                                        <div className="h-64 w-44 overflow-hidden rounded-lg">
                                            <img
                                            src="https://ik.imagekit.io/l3gffggj0/shubify/gallery_images/Shubify-11.png?updatedAt=1709474988424"
                                            referrerPolicy="no-referrer"
                                            alt=""
                                            className="h-full w-full object-cover object-center"
                                            ></img>
                                        </div>
                                        <div className="h-64 w-44 overflow-hidden rounded-lg">
                                            <img
                                            src="https://ik.imagekit.io/l3gffggj0/shubify/gallery_images/Shubify-5.png?updatedAt=1709474991695"
                                            referrerPolicy="no-referrer"
                                            alt=""
                                            className="h-full w-full object-cover object-center"
                                            ></img>
                                        </div>
                                    </div>
                                    <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                                        <div className="h-64 w-44 overflow-hidden rounded-lg">
                                            <img
                                            src="https://ik.imagekit.io/l3gffggj0/shubify/gallery_images/Shubify-7-preview-1.png?updatedAt=1709474994109"
                                            referrerPolicy="no-referrer"
                                            alt=""
                                            className="h-full w-full object-cover object-center"
                                            ></img>
                                        </div>
                                        <div className="h-64 w-44 overflow-hidden rounded-lg">
                                            <img
                                            src="https://ik.imagekit.io/l3gffggj0/shubify/gallery_images/Shubify-4.png?updatedAt=1709474991101"
                                            referrerPolicy="no-referrer"
                                            alt=""
                                            className="h-full w-full object-cover object-center"
                                            ></img>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-16 px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
                <div className="grid grid-cols-2 grid-rows-2 gap-4 sm:gap-6 lg:gap-8">
                    <img
                        src="https://ik.imagekit.io/l3gffggj0/shubify/gallery_images/Shubify-6.png?updatedAt=1709474991156"
                        alt="Walnut card tray with white powder coated steel divider and 3 punchout holes."
                        className="rounded-lg bg-gray-100" 
                        referrerPolicy="no-referrer"
                    ></img>
                    <img
                        src="https://ik.imagekit.io/l3gffggj0/shubify/gallery_images/Shubify-2.jpg?updatedAt=1709474988113"
                        alt="Top down view of walnut card tray with embedded magnets and card groove."
                        className="rounded-lg bg-gray-100"
                        referrerPolicy="no-referrer"
                    ></img>
                    <img
                        src="https://ik.imagekit.io/l3gffggj0/shubify/gallery_images/Shubify-12.png?updatedAt=1709474990740"
                        alt="Side of walnut card tray with card groove and recessed card area."
                        className="rounded-lg bg-gray-100" 
                        referrerPolicy="no-referrer"
                    ></img>
                    <img
                        src="https://ik.imagekit.io/l3gffggj0/shubify/gallery_images/Shubify-3.png?updatedAt=1709474987632"
                        alt="Walnut card tray filled with cards and card angled in dedicated groove."
                        className="rounded-lg bg-gray-100" 
                        referrerPolicy="no-referrer"
                    ></img>
                </div>
                <div>
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">What do I do</h2>
                    <p className="mt-4 text-gray-500">
                        Passion towards painting and crafts pushed me to explore several options  
                    </p>

                    <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
                        {features.map((feature) => (
                        <div key={feature.name} className="border-t border-gray-200 pt-4">
                            <dt className="font-medium text-gray-900">{feature.name}</dt>
                            <dd className="mt-2 text-sm text-gray-500">{feature.description}</dd>
                        </div>
                        ))}
                    </dl>
                </div>
            </div>

            <div className="bg-white py-24 sm:py-32">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl lg:text-center">
                    <h2 className="text-base font-semibold leading-7 text-indigo-600">100% Attention to Details</h2>
                    <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                        Everything that you need to beautify your home / office interiors
                    </p>
                    <p className="mt-6 text-lg leading-8 text-gray-600">
                    Explore our vibrant collection of acrylic paintings that capture the essence of imagination and beauty. Whether you're an art enthusiast, a seasoned collector, or someone simply looking to add a touch of artistry to your space, you'll find something here to inspire and delight. Dive in and discover the magic of acrylic artistry!
                    </p>
                    </div>
                    <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
                    <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                        {features.map((feature) => (
                        <div key={feature.name} className="relative pl-16">
                            <dt className="text-base font-semibold leading-7 text-gray-900">
                            <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                                <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                            </div>
                            {feature.name}
                            </dt>
                            <dd className="mt-2 text-base leading-7 text-gray-600">{feature.description}</dd>
                        </div>
                        ))}
                    </dl>
                    </div>
                </div>
            </div>

            <div className = "mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8" >
                <h2 className = "text-2xl font-bold tracking-tight text-gray-900" > Recent purchases </h2>

                <div className = "mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8" > {
                    products.map((product) => ( 
                    <div key = { product.id } className = "group relative" >
                        <div className = "aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-80" >
                        <img src = { product.imageSrc } referrerPolicy="no-referrer" alt = { product.imageAlt } className = "h-full w-full object-cover object-center lg:h-full lg:w-full"></img>
                        </div> 
                        <div className = "mt-4 flex justify-between" >
                        <div>
                        <h3 className = "text-sm text-gray-700" >
                        <a href = { product.href } >
                        <span aria-hidden = "true" className = "absolute inset-0" /> { product.name } </a> 
                        </h3 > 
                        <p className = "mt-1 text-sm text-gray-500" > { product.color } </p> </div > 
                        <p className = "text-sm font-medium text-gray-900" > { product.price } </p> </div> </div>
                    ))
                } 
                </div> 
            </div>

            <section className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:px-8">
                <div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.indigo.100),white)] opacity-20" />
                <div className="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
                <div className="mx-auto max-w-2xl lg:max-w-4xl">
                    <figure className="mt-10">
                    <blockquote className="text-center text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9">
                        <p>
                        “Brought Life to My Living Room! Thanks to Shubify!”
                        </p>
                    </blockquote>
                    <figcaption className="mt-10">
                        <img
                        className="mx-auto h-10 w-10 rounded-full"
                        src="https://lh3.googleusercontent.com/a/ACg8ocLnipat_5-VHLbG81GRxUX5NlPDU1A1C4PeGgL-vSTbrKo=s576-c-no"
                        alt=""
                        ></img>
                        <div className="mt-4 flex items-center justify-center space-x-3 text-base">
                        <div className="font-semibold text-gray-900">Suresh Natarajan</div>
                        <svg viewBox="0 0 2 2" width={3} height={3} aria-hidden="true" className="fill-gray-900">
                            <circle cx={1} cy={1} r={1} />
                        </svg>
                        <div className="text-gray-600">Basavanagudi, Bangalore</div>
                        </div>
                    </figcaption>
                    </figure>
                </div>
            </section>
            
            <footer className="relative bg-blueGray-200 pt-8 pb-6">
                <div className="container mx-auto px-4">
                    <div className="flex flex-wrap text-left lg:text-left">
                    <div className="w-full lg:w-6/12 px-4">
                        <h4 className="text-3xl fonat-semibold text-blueGray-700">Meet our founder</h4>
                        <h5 className="text-lg mt-0 mb-2 text-blueGray-600">
                            Passionate Painter; Engineering Graduate; IT-Professional; Proud Mother; 
                        </h5>
                        <ul role="list" className="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
                            {people.map((person) => (
                                <li key={person.name}>
                                <div className="flex items-center gap-x-6">
                                    <img className="h-16 w-16 rounded-full" src={person.imageUrl} alt="" ></img>
                                    <div>
                                    <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">{person.name}</h3>
                                    <p className="text-sm font-semibold leading-6 text-indigo-600">{person.role}</p>
                                    </div>
                                </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="w-full lg:w-6/12 px-4">
                        <div className="flex flex-wrap items-top mb-6">
                            <div className="w-full lg:w-4/12 px-4 ml-auto">
                                <span className="mt-6 block uppercase text-blueGray-500 text-sm font-semibold mb-2">Connect with us</span>
                                <div className="mt-6 lg:mb-0 mb-6">
                                    <button className="bg-white text-lightBlue-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2" type="button">
                                        <i className="fab fa-twitter"></i>
                                    </button>
                                    <button className="bg-white text-lightBlue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2" type="button">
                                        <i className="fab fa-facebook-square"></i>
                                    </button>
                                    <button className="bg-white text-pink-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2" type="button">
                                        <i className="fab fa-instagram"></i>
                                    </button>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <hr className="my-6 border-blueGray-300" />
                    <div className="flex flex-wrap items-center md:justify-between justify-center">
                    <div className="w-full md:w-4/12 px-4 mx-auto text-center">
                        <div className="text-sm text-blueGray-500 font-semibold py-1">
                        Copyright © <span id="get-current-year">2023</span>
                        <span className="text-blueGray-500 hover:text-gray-800"> Developed by </span>
                        <a href="https://vaaniga.com" target="_blank" className="text-blueGray-500 hover:text-blueGray-800">VAANIGA.com</a>
                        </div>
                    </div>
                    </div>
                </div>
            </footer>

        </div>
    )
}